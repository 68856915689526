:root {
  --green: #075B52;
}

.main-title {
  color: var(--green);
}

.main-button {
  background: var(--green) !important;
  border-color: var(--green) !important;
}

.main-bg {
  background: url("./wslg-chat-bg.png");
}
